import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

let routes = [
	{
		path: '/',
		name: 'home',
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
	},
	{
		path: '/:channel(channel[1-9]\\d?)',
		name: 'homechannel',
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			console.log(`访问的是 /${to.params.channel}`)
			next()
		},
	},
	{
		path: '/privacy-policy',
		name: 'privacy',
		component: () => import('../pages/privacy.vue'),
	},
	{
		path: '/terms-of-services',
		name: 'terms',
		component: () => import('../pages/terms.vue'),
	},
	{
		path: '/novel',
		name: 'novel',
		component: () => import(/* webpackChunkName: "novel" */ '../pages/novel.vue'),
	},
	{
		path: '/:channel(channel[1-9]\\d?)/novel',
		name: 'novelchannel',
		component: () => import(/* webpackChunkName: "novel" */ '../pages/novel.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			console.log(`访问的是 /${to.params.channel}/novel`)
			next()
		},
	},
	// 小说详情页路由 - 需要放在普通文章路由前面
	{
		path: '/:channel(channel[1-9]\\d?)/novel/:novelId',
		name: 'noveldetailchannel',
		component: () => import(/* webpackChunkName: "novel-detail" */ '../pages/novel-detail.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			console.log(`访问的是 /${to.params.channel}/novel/${to.params.novelId}`)
			next()
		},
	},
	{
		path: '/novel/:novelId',
		name: 'noveldetail',
		component: () => import(/* webpackChunkName: "novel-detail" */ '../pages/novel-detail.vue'),
		props: true,
	},
	// // 普通文章路由
	// {
	// 	path: '/:channel(channel[1-9]\\d?)/:part/:chapters',
	// 	name: 'detailchannel',
	// 	component: () => import(/* webpackChunkName: "detail" */ '../pages/detail.vue'),
	// 	props: true,
	// 	beforeEnter: (to, from, next) => {
	// 		console.log(`访问的是 /${to.params.channel}/${to.params.part}/${to.params.chapters}`)
	// 		next()
	// 	},
	// },
	// {
	// 	path: '/:part/:chapters',
	// 	name: 'detail',
	// 	component: () => import(/* webpackChunkName: "detail" */ '../pages/detail.vue'),
	// 	props: true,
	// },
	{
		path: '/:channel(channel[1-9]\\d?)/:tags',
		name: 'tagschannel',
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			console.log(`访问的是 /${to.params.channel}/${to.params.tags}`)
			next()
		},
	},
	{
		path: '/:channel(channel[1-9]\\d?)/:cat/:id/:title',
		name: 'detailchannel',
		component: () => import(/* webpackChunkName: "home" */ '../pages/detail.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			console.log(`访问的是 /${to.params.channel}/${to.params.tags}`)
			next()
		},
	},
	{
		path: '/:tags',
		name: 'tags',
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
	},
	{
		path: '/:cat/:id/:title',
		name: 'detail',
		component: () => import(/* webpackChunkName: "home" */ '../pages/detail.vue'),
	},
]

export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
